import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { ConfigService } from '../../@vex/config/config.service';
import { LayoutService } from '../../@vex/services/layout.service';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BaseComponent } from '../shared/components/baseComponent';

@Component({
  selector: 'feui-base-layout',
  templateUrl: './base-layout.component.html',
})
export class BaseLayoutComponent extends BaseComponent {
  private layoutService: LayoutService = inject(LayoutService);
  private configService: ConfigService = inject(ConfigService);
  private router: Router = inject(Router);

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(
    this.untilDestroyed(),
    map((config) => config.footer.visible)
  );
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    this.untilDestroyed(),
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, (data) => data.toolbarShadowEnabled))
  );
}
