import { Component, inject, OnDestroy } from "@angular/core";
import { MonoTypeOperatorFunction, Observable, of, Subject, takeUntil } from "rxjs";
import { Rights } from "src/enumerations/right.enum";
import { Platform } from "@angular/cdk/platform";
import { hasRight } from "../../auth/auth-store/auth.selectors";
import { Store } from "@ngrx/store";
import { AuthState } from "../../auth/auth-store/auth.reducer";

@Component({
  selector: "feui-ct-not-usable-base", template: ""
})
export class BaseComponent implements OnDestroy {
  protected componentDestroyed$ = new Subject<void>();
  protected authStore: Store<AuthState> = inject(Store<AuthState>);
  protected canReadResolved: boolean = false;
  protected canDeleteResolved: boolean = false;
  protected canCreateResolved: boolean = false;
  protected canModifyResolved: boolean = false;
  protected canRestoreResolved: boolean = false;
  protected canGrantReadResolved: boolean = false;
  protected canGrantCreateModifyResolved: boolean = false;
  protected canGrantDeleteRestoreResolved: boolean = false;
  protected platform: Platform = inject(Platform);
  private defaultResource: string;

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  protected setDefaultResource(defaultResource: string): void {
    this.defaultResource = defaultResource;
    this.canModify().pipe(this.untilDestroyed()).subscribe((canModify) => this.canModifyResolved = canModify);
    this.canRead().pipe(this.untilDestroyed()).subscribe((canRead) => this.canReadResolved = canRead);
    this.canCreate().pipe(this.untilDestroyed()).subscribe((canCreate) => this.canCreateResolved = canCreate);
    this.canDelete().pipe(this.untilDestroyed()).subscribe((canDelete) => this.canDeleteResolved = canDelete);
    this.canRestore().pipe(this.untilDestroyed()).subscribe((canRestore) => this.canRestoreResolved = canRestore);
    this.canGrantRead().pipe(this.untilDestroyed()).subscribe(
      (canGrantRead) => this.canGrantReadResolved = canGrantRead);
    this.canGrantCreateModify().pipe(this.untilDestroyed()).subscribe(
      (canGrantCreateModify) => this.canGrantCreateModifyResolved = canGrantCreateModify);
    this.canGrantDeleteRestore().pipe(this.untilDestroyed()).subscribe(
      (canGrantDeleteRestore) => this.canGrantDeleteRestoreResolved = canGrantDeleteRestore);
  }

  protected canRead(): Observable<boolean> {
    return this.hasRight(Rights.READ);
  }

  protected canCreate(): Observable<boolean> {
    return this.hasRight(Rights.CREATE);
  }

  protected canModify(): Observable<boolean> {
    return this.hasRight(Rights.MODIFY);
  }

  protected canDelete(): Observable<boolean> {
    return this.hasRight(Rights.DELETE);
  }

  protected canRestore(): Observable<boolean> {
    return this.hasRight(Rights.RESTORE);
  }

  protected canGrantRead(): Observable<boolean> {
    return this.hasRight(Rights.GRANT_READ);
  }

  protected canGrantCreateModify(): Observable<boolean> {
    return this.hasRight(Rights.GRANT_CREATE_MODIFY);
  }

  protected canGrantDeleteRestore(): Observable<boolean> {
    return this.hasRight(Rights.GRANT_DELETE_RESTORE);
  }

  protected hasRight(right: Rights, resource: string = this.defaultResource): Observable<boolean> {
    if (resource) {
      return this.authStore.select(hasRight(right, resource));
    }
    return of(false);
  }

  protected untilDestroyed<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil<T>(this.componentDestroyed$);
  }
}
