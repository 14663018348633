import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { PopoverRef } from "../../../../components/popover/popover-ref";
import { MenuItem } from "../interfaces/menu-item.interface";
import { select, Store } from "@ngrx/store";
import { getNameOfUser, isLoggedIn } from "../../../../../app/auth/auth-store/auth.selectors";
import { Observable } from "rxjs";
import { AuthState } from "../../../../../app/auth/auth-store/auth.reducer";

export interface OnlineStatus {
  id: "online" | "away" | "dnd" | "offline";
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: "vex-toolbar-user-dropdown",
  templateUrl: "./toolbar-user-dropdown.component.html",
  styleUrls: ["./toolbar-user-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {
  items: MenuItem[] = [{
    id: "1",
    icon: "mat:account_circle",
    label: "My Profile (!DUMMY!)",
    description: "Personal Information",
    colorClass: "text-teal",
    route: "/apps/social"
  }, {
    id: "2",
    icon: "mat:move_to_inbox",
    label: "My Inbox (!DUMMY!)",
    description: "Messages & Latest News",
    colorClass: "text-primary",
    route: "/apps/chat"
  }, {
    id: "3",
    icon: "mat:list_alt",
    label: "My Projects (!DUMMY!)",
    description: "Tasks & Active Projects",
    colorClass: "text-amber",
    route: "/apps/scrumboard"
  }, {
    id: "4",
    icon: "mat:table_chart",
    label: "Billing Information (!DUMMY!)",
    description: "Pricing & Current Plan",
    colorClass: "text-purple",
    route: "/pages/pricing"
  }];
  isLoggedIn$: Observable<boolean>;
  nameOfUser$: Observable<string>;
  buttonTitle: string = "userMenu.login";
  private popoverRef: PopoverRef<ToolbarUserDropdownComponent> = inject(PopoverRef<ToolbarUserDropdownComponent>);
  private authStore: Store<AuthState> = inject(Store<AuthState>);

  ngOnInit() {
    this.isLoggedIn$ = this.authStore.pipe(select(isLoggedIn));
    this.nameOfUser$ = this.authStore.pipe(select(getNameOfUser));

    this.isLoggedIn$.subscribe({
      next: (value) => {
        if (value) {
          this.buttonTitle = "userMenu.logout";
        } else {
          this.buttonTitle = "userMenu.login";
        }
      }
    });
  }

  close() {
    this.popoverRef.close();
  }
}
