import { AuthState } from "./auth.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import {
  NavigationDropdown,
  NavigationItem,
  NavigationLink,
  NavigationSubheading
} from "../../../@vex/interfaces/navigation-item.interface";
import { Rights } from "../../../enumerations/right.enum";

export const AVAILABLE_NAVIGATION_TREE: NavigationItem[] = [{
  type: "link", label: "menu.home", route: "/", icon: "mat:home", routerLinkActiveOptions: { exact: true }
}, {
  type: "link",
  label: "menu.championstour",
  route: "/championstour",
  icon: "mat:directions_run",
  routerLinkActiveOptions: { exact: true }
}, {
  type: "link",
  label: "menu.european-championship",
  route: "/european-championship",
  icon: "mat:directions_run",
  routerLinkActiveOptions: { exact: true }
}, {
  type: "subheading", label: "menu.admin.access-control", children: [{
    type: "link",
    label: "menu.admin.user",
    route: "/admin/authorization/user",
    resource: "user",
    icon: "mat:perm_identity",
    routerLinkActiveOptions: { exact: true }
  }, {
    type: "link",
    label: "menu.admin.roles",
    resource: "role",
    route: "/admin/authorization/role",
    icon: "mat:confirmation_number",
    routerLinkActiveOptions: { exact: true }
  }]
}, {
  type: "subheading", label: "menu.admin.master-data", children: [{
    type: "link",
    label: "menu.admin.seasons",
    route: "/admin/masterdata/season",
    resource: "season",
    icon: "mat:calendar_today",
    routerLinkActiveOptions: { exact: true }
  }, {
    type: "link",
    label: "menu.admin.age-groups",
    resource: "ageGroup",
    route: "/admin/masterdata/age-group",
    icon: "mat:group",
    routerLinkActiveOptions: { exact: true }
  }]
}, {
  type: "subheading", label: "menu.admin.championships", children: [{
    type: "link",
    label: "menu.admin.european-championships",
    resource: "european-championship",
    route: "/admin/european-championship",
    icon: "mat:directions_run",
    routerLinkActiveOptions: { exact: true }
  }, {
    type: "link",
    label: "menu.admin.championstour-bosseln",
    route: "/admin/championstour/bosseln",
    resource: "championstour-bosseln",
    icon: "mat:directions_run",
    routerLinkActiveOptions: { exact: true }
  }, {
    type: "link",
    label: "menu.admin.championstour-kloot",
    route: "/admin/championstour/kloot",
    resource: "championstour-kloot",
    icon: "mat:directions_run",
    routerLinkActiveOptions: { exact: true }
  }]
}];

export const getAuthState = createFeatureSelector<AuthState>("auth");

export const getTokenInformation = createSelector(getAuthState, (state: AuthState) => state.token);

/**
 * user information
 */

export const getUser = createSelector(getAuthState, (state: AuthState) => state.user);

export const getNameOfUser = createSelector(getAuthState,
  (state) => state.user != undefined ? `${state.user.name} ${state.user.lastname}` : "");

export const hasRight = (expectedAccessLevel: number, requestedResource: string) => createSelector(getAuthState,
  (state: AuthState) => {
    return hasRightInternal(state, expectedAccessLevel, requestedResource);
  });

export const isLoggedIn = createSelector(getAuthState, (state: AuthState) => state.user != undefined);

/**
 * menu information
 */

export const getMenuItems = createSelector(getAuthState, (state: AuthState) => {
  return buildNavigationTree(state, AVAILABLE_NAVIGATION_TREE);
});

/**
 * HELPERS
 */

function hasRightInternal(state: AuthState, expectedAccessLevel: number, requestedResource: string): boolean {
  let result = false;
  if (state.user != undefined) {
    let resourceRoles = state.acl.resources.find((a) => a.resourceName == requestedResource);
    resourceRoles?.roles.forEach((role) => {
      let userRole = state.user.roles.find((r) => r == role.roleName);
      if (userRole != undefined) {
        if (checkRightInternal(expectedAccessLevel, role.rightLevel)) {
          result = true;
        }
      }
    });
  }
  return result;
}

function checkRightInternal(expectedAccessLevel: number, userAccessLevel: number): boolean {
  return (userAccessLevel & expectedAccessLevel) == expectedAccessLevel;
}

function buildNavigationTree(state: AuthState, navItems: NavigationItem[]): NavigationItem[] {
  let result: NavigationItem[] = [];

  for (let i = 0; i < navItems.length; i++) {
    const navItem = navItems[i];
    if (navItem.type == "link") {
      if (userCanAccessLink(state, navItem)) result.push(navItem);
    } else {
      if (checkIfTreeHasAccessibleLink(state, navItem)) {
        let newItem: NavigationItem;
        switch (navItem.type) {
          case "dropdown":
            newItem = {
              label: navItem.label,
              resource: navItem.resource,
              type: "dropdown",
              icon: navItem.icon,
              children: [],
              badge: navItem.badge
            } as NavigationDropdown;
            break;
          case "subheading":
            newItem = {
              label: navItem.label, resource: navItem.resource, type: "subheading", children: []
            } as NavigationSubheading;
            break;
        }
        newItem.children = buildNavigationTreeChildren(state, navItem.children);
        result.push(newItem);
      }
    }
  }

  return result;
}

function buildNavigationTreeChildren(state: AuthState,
                                     navItems: (NavigationDropdown | NavigationLink)[]): (NavigationDropdown | NavigationLink)[] {
  let result: (NavigationDropdown | NavigationLink)[] = [];

  for (let i = 0; i < navItems.length; i++) {
    const navItem = navItems[i];
    if (navItem.type == "link") {
      if (userCanAccessLink(state, navItem)) result.push(navItem);
    } else {
      if (checkIfTreeHasAccessibleLink(state, navItem)) {
        let newItem: NavigationDropdown;
        newItem = {
          label: navItem.label,
          resource: navItem.resource,
          type: "dropdown",
          icon: navItem.icon,
          children: [],
          badge: navItem.badge
        } as NavigationDropdown;
        newItem.children = buildNavigationTreeChildren(state, navItem.children);
        result.push(newItem);
      }
    }
  }

  return result;
}

function checkIfTreeHasAccessibleLink(state: AuthState, navItem: NavigationItem): boolean {
  let result = false;

  if (navItem.type == "link") {
    if (userCanAccessLink(state, navItem)) {
      return true;
    }
  } else {
    for (let i = 0; i < navItem.children.length; i++) {
      const item = navItem.children[i];
      if (checkIfTreeHasAccessibleLink(state, item)) {
        result = true;
      }
    }
  }

  return result;
}

function userCanAccessLink(state: AuthState, navItem: NavigationLink): boolean {
  if (navItem.resource == undefined) return true;
  return hasRightInternal(state, Rights.READ, navItem.resource);
}
