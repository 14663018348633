import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, inject, LOCALE_ID, Renderer2 } from "@angular/core";
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Settings } from "luxon";
import { ColorSchemeName } from "../@vex/config/colorSchemeName";
import { VexConfigName } from "../@vex/config/config-name.model";
import { ConfigService } from "../@vex/config/config.service";
import { Actions, ofType } from "@ngrx/effects";
import {
  AuthActionTypes,
  AuthStorageAction,
  logoutFinished,
  updateUserInformation
} from "./auth/auth-store/auth.actions";
import { Store } from "@ngrx/store";
import { AuthState } from "./auth/auth-store/auth.reducer";
import { HttpErrorResponse } from "@angular/common/http";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { LayoutService } from "../@vex/services/layout.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "vex-root", templateUrl: "./app.component.html"
})
export class AppComponent {
  private configService: ConfigService = inject(ConfigService);
  private renderer: Renderer2 = inject(Renderer2);
  private platform: Platform = inject(Platform);
  private readonly matIconRegistry: MatIconRegistry = inject(MatIconRegistry);
  private readonly domSanitizer: DomSanitizer = inject(DomSanitizer);
  private router: Router = inject(Router);
  private actions$: Actions = inject(Actions);
  private authStore: Store<AuthState> = inject(Store<AuthState>);
  private splashScreenService: SplashScreenService = inject(SplashScreenService);
  private layoutService: LayoutService = inject(LayoutService);
  private route: ActivatedRoute = inject(ActivatedRoute);
  private translate: TranslateService = inject(TranslateService);

  constructor(@Inject(DOCUMENT) private document: Document, @Inject(LOCALE_ID) private localeId: string) {
    this.translate.addLangs(['de', 'en']);
    this.translate.setDefaultLang('de');
    this.translate.use(this.translate.getBrowserLang());

    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.matIconRegistry.addSvgIconResolver(
      (name: string, namespace: string): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case "mat":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`);

          case "flag":
            return this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/icons/flags/${name}.svg`);
        }
      });

    this.configService.updateConfig({
      sidenav: {
        title: "", imageUrl: "assets/img/logo.png", showCollapsePin: false, user: {
          visible: false
        }, search: {
          visible: false
        }
      }, boxed: false, footer: {
        fixed: false
      }, style: {
        colorScheme: ColorSchemeName.light
      }, id: VexConfigName.apollo, toolbar: {
        fixed: true, user: {
          visible: true
        }
      }
    });

    this.actions$.pipe(ofType(AuthActionTypes.LoginFinished)).subscribe(() => {
      this.authStore.dispatch(updateUserInformation());
    });

    this.renderer.listen("window", "storage", event => {
      this.authStore.dispatch(new AuthStorageAction(event.key));
    });

    this.actions$.pipe(ofType(AuthActionTypes.RefreshTokenFailed)).subscribe(
      (error: { error: HttpErrorResponse | Error }) => {
        console.log(error.error.message);
        this.authStore.dispatch(logoutFinished());
      });

    this.actions$.pipe(ofType(AuthActionTypes.LogoutFinished)).subscribe(() => {
      this.router.navigate(["/login"]);
    });
  }
}
