<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div class="dropdown-heading-icon flex items-center justify-center">
        <mat-icon svgIcon="mat:person"></mat-icon>
      </div>
      <div class="dropdown-heading">{{ nameOfUser$ | async }}</div>
    </div>

    <!--<button [matMenuTriggerFor]="settingsMenu" mat-icon-button matTooltip="Settings" matTooltipPosition="before" type="button" *ngIf="isLoggedIn()">
      <mat-icon class="notifications-header-icon" svgIcon="mat:settings"></mat-icon>
    </button>-->
  </div>

  <div class="dropdown-content" *ngIf="isLoggedIn$ | async">
    <!--<a (click)="close()" *ngFor="let item of items; trackBy: trackById" [routerLink]="item.route" class="notification flex items-center" matRipple disabled="true">
      <mat-icon [svgIcon]="item.icon" [ngClass]="item.colorClass" class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ item.label }}</div>
        <div class="notification-description">{{ item.description }}</div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>-->
  </div>

  <div class="dropdown-content" *ngIf="!(isLoggedIn$ | async)">
    <p class="notification flex items-center">
      {{ "userMenu.loginText" | translate }}
    </p>
  </div>

  <div class="dropdown-footer flex items-center justify-between">
    <span *ngIf="isLoggedIn$ | async">&nbsp;</span>
    <!--<a (click)="close()" *ngIf="!isLoggedIn()" [routerLink]="['/signup']" color="primary" mat-button>{{ 'userMenu.signup' | translate }}</a>-->
    <a (click)="close()" [routerLink]="['/login']" color="primary" mat-button>{{ buttonTitle | translate }}</a>
  </div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item [disabled]="false">
    <mat-icon svgIcon="mat:business"></mat-icon>
    <span>Change Address (!DUMMY!)</span>
  </button>

  <button mat-menu-item [disabled]="false">
    <mat-icon svgIcon="mat:verified_user"></mat-icon>
    <span>Change Username (!DUMMY!)</span>
  </button>

  <button mat-menu-item [disabled]="false">
    <mat-icon svgIcon="mat:lock"></mat-icon>
    <span>Change Password (!DUMMY!)</span>
  </button>

  <button mat-menu-item [disabled]="false">
    <mat-icon svgIcon="mat:notifications_off"></mat-icon>
    <span>Disable Notifications (!DUMMY!)</span>
  </button>
</mat-menu>
