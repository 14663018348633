import { ITokenResponse } from "../../../interfaces/tokenResponse.interface";
import {
  AUTH_STORAGE,
  AuthStorageAction,
  loginFinished,
  logoutFinished,
  refreshTokenFinished,
  updateUserInformationFinished
} from "./auth.actions";
import { IAcl } from "../../../interfaces/acl.interface";
import { IUser } from "../../../interfaces/user.interface";
import { ActionReducer, createReducer, on, State } from "@ngrx/store";
import { localStorageSync, rehydrateApplicationState } from "ngrx-store-localstorage";

/**
 * contains all authentication related information
 */
export interface AuthState {
  token: ITokenResponse;
  acl: IAcl;
  user: IUser;
}

/**
 * initial state of authentication related information
 */
export const initialAuthState: AuthState = {
  token: null, acl: null, user: null
};

export const authReducer = createReducer(initialAuthState, on(loginFinished, (state, { token }) => {
  return {
    ...state, token: token
  };
}), on(logoutFinished, (state) => {
  return {
    token: null, acl: null, user: null
  };
}), on(refreshTokenFinished, (state, { token }) => {
  return {
    ...state, token: token
  };
}), on(updateUserInformationFinished, (state, { user }) => {
  return {
    ...state, user: user.user, acl: user.acl
  };
}));

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: State<any>, action: AuthStorageAction) => {
    const keys = ["auth"]; // store keys to sync between tabs

    if (action.type === AUTH_STORAGE && keys.includes(action.payload)) {
      const rehydratedState = rehydrateApplicationState([action.payload], localStorage, k => k, true);
      return { ...state, ...rehydratedState };
    }

    return localStorageSync({
      keys, rehydrate: true
    })(reducer)(state, action);
  };
}
