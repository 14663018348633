import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { versionInfo } from 'src/environments/versionInformation';

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() customTemplate: TemplateRef<any>;

  public param = {
    year: 0,
    author: versionInfo.author,
    version: versionInfo.version,
    buildDate: versionInfo.buildDate
  };

  constructor() {
    this.param.year = new Date().getFullYear();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {}
}
