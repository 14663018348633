import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import localeEn from "@angular/common/locales/en";
import localeEnExtra from "@angular/common/locales/extra/en";
import localeIt from "@angular/common/locales/it";
import localeItExtra from "@angular/common/locales/extra/it";
import localeNl from "@angular/common/locales/nl";
import localeNlExtra from "@angular/common/locales/extra/nl";
import { NgModule } from "@angular/core";
import { MatDatepickerIntl } from "@angular/material/datepicker";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { QuicklinkModule } from "ngx-quicklink";
import { VexModule } from "../@vex/vex.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BaseLayoutModule } from "./base-layout/base-layout.module";
import { CustomMatDatepickerIntl } from "./customMatDatepickerIntl";
import { CustomMatPaginatorIntl } from "./customMatPaginatorIntl";
import { HttpLoaderFactory, SharedModule } from "./shared/shared.module";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { AuthEffects } from "./auth/auth-store/auth.effects";
import { ActionReducerMap, MetaReducer, StoreModule } from "@ngrx/store";
import { authReducer, localStorageSyncReducer } from "./auth/auth-store/auth.reducer";

export const reducers: ActionReducerMap<any> = { auth: authReducer };

export const metaReducers: MetaReducer[] = [localStorageSyncReducer];

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule,
            AppRoutingModule,
            BrowserAnimationsModule,
            HttpClientModule,

    // Vex
            VexModule,
            BaseLayoutModule,

    // Custom
            TranslateModule.forRoot({
              loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
              }, defaultLanguage: "de", isolate: false
            }),
            SharedModule.forRoot(),
            MatSnackBarModule,
            QuicklinkModule,

    // ngrx
            StoreModule.forRoot(reducers, { metaReducers }),
            EffectsModule.forRoot([AuthEffects]),
            StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: MatDatepickerIntl, useClass: CustomMatDatepickerIntl }],
  bootstrap: [AppComponent],
  exports: [QuicklinkModule]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, "de-DE", localeDeExtra);
    registerLocaleData(localeEn, "en-IE", localeEnExtra);
    registerLocaleData(localeIt, "it-IT", localeItExtra);
    registerLocaleData(localeNl, "nl-NL", localeNlExtra);
  }
}
