import { Component, inject, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, of } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { PopoverService } from "../../components/popover/popover.service";
import { SearchModalComponent } from "../../components/search-modal/search-modal.component";
import { UserMenuComponent } from "../../components/user-menu/user-menu.component";
import { ConfigService } from "../../config/config.service";
import { NavigationLink } from "../../interfaces/navigation-item.interface";
import { LayoutService } from "../../services/layout.service";
import { Store } from "@ngrx/store";
import { AuthState } from "../../../app/auth/auth-store/auth.reducer";
import { getMenuItems } from "../../../app/auth/auth-store/auth.selectors";

@Component({
  selector: "vex-sidenav", templateUrl: "./sidenav.component.html", styleUrls: ["./sidenav.component.scss"]
})
export class SidenavComponent {
  @Input() collapsed: boolean;
  userMenuOpen$: Observable<boolean> = of(false);
  private layoutService: LayoutService = inject(LayoutService);
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  private configService: ConfigService = inject(ConfigService);
  title$ = this.configService.config$.pipe(map((config) => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map((config) => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map((config) => config.sidenav.showCollapsePin));
  userVisible$ = this.configService.config$.pipe(map((config) => config.sidenav.user.visible));
  searchVisible$ = this.configService.config$.pipe(map((config) => config.sidenav.search.visible));
  private readonly popoverService: PopoverService = inject(PopoverService);
  private readonly dialog: MatDialog = inject(MatDialog);
  private authStore: Store<AuthState> = inject(Store<AuthState>);
  items$ = this.authStore.select(getMenuItems);

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(this.popoverService.open({
      content: UserMenuComponent, origin, offsetY: -8, width: origin.clientWidth, position: [{
        originX: "center", originY: "top", overlayX: "center", overlayY: "bottom"
      }]
    })).pipe(switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))), startWith(true));
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: "vex-dialog-glossy", width: "100%", maxWidth: "600px"
    });
  }
}
