import { inject, Injectable, OnDestroy } from '@angular/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomMatDatepickerIntl extends MatDatepickerIntl implements OnDestroy {
  private translate: TranslateService = inject(TranslateService);

  unsubscribe: Subject<void> = new Subject<void>();

  private translationStrings: string[] = [
    'formControls.datepicker.calendarLabel',
    'formControls.datepicker.openCalendarLabel',
    'formControls.datepicker.closeCalendarLabel',
    'formControls.datepicker.prevMonthLabel',
    'formControls.datepicker.nextMonthLabel',
    'formControls.datepicker.prevYearLabel',
    'formControls.datepicker.nextYearLabel',
    'formControls.datepicker.prevMultiYearLabel',
    'formControls.datepicker.nextMultiYearLabel',
    'formControls.datepicker.switchToMonthViewLabel',
    'formControls.datepicker.switchToMultiYearViewLabel',
    'formControls.datepicker.startDateLabel',
    'formControls.datepicker.endDateLabel',
  ];

  constructor() {
    super();

    this.translate.onLangChange.subscribe(() => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getAndInitTranslations() {
    this.translate.get(this.translationStrings).subscribe((translation) => {
      this.calendarLabel = translation[this.translationStrings[0]];
      this.openCalendarLabel = translation[this.translationStrings[1]];
      this.closeCalendarLabel = translation[this.translationStrings[2]];
      this.prevMonthLabel = translation[this.translationStrings[3]];
      this.nextMonthLabel = translation[this.translationStrings[4]];
      this.prevYearLabel = translation[this.translationStrings[5]];
      this.nextYearLabel = translation[this.translationStrings[6]];
      this.prevMultiYearLabel = translation[this.translationStrings[7]];
      this.nextMultiYearLabel = translation[this.translationStrings[8]];
      this.switchToMonthViewLabel = translation[this.translationStrings[9]];
      this.switchToMultiYearViewLabel = translation[this.translationStrings[10]];
      this.startDateLabel = translation[this.translationStrings[11]];
      this.endDateLabel = translation[this.translationStrings[12]];
      this.changes.next();
    });
  }

  formatYearRange(start: string, end: string): string {
    return `${start} - ${end}`;
  }

  formatYearRangeLabel(start: string, end: string): string {
    return `${start} - ${end}`;
  }
}
