import { Component, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthState } from "../../../app/auth/auth-store/auth.reducer";
import { getMenuItems } from "../../../app/auth/auth-store/auth.selectors";

@Component({
  selector: "vex-navigation", templateUrl: "./navigation.component.html", styleUrls: ["./navigation.component.scss"]
})
export class NavigationComponent {
  private authStore: Store<AuthState> = inject(Store<AuthState>);

  items$ = this.authStore.select(getMenuItems);
}
