import { CommonModule, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoaderComponent } from './components/loader/loader.component';
import { RankedPlaceComponent } from './components/ranked-place/ranked-place.component';
import { YesNoDialogComponent } from './components/yes-no-dialog/yes-no-dialog.component';
import { DetectFocusDirective } from './directives/detect-focus.directive';
import { SelectOnFocusDirective } from './directives/select-on-focus.directive';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { AgeGroupNamePipe } from './pipes/ageGroupNamePipe/age-group-name.pipe';
import { AgegroupFilterPipe } from './pipes/agegroup-filter-pipe/agegroup-filter.pipe';
import { NameFilterPipe } from './pipes/name-filter-pipe/name-filter.pipe';
import { PointsPlaceMappingPipe } from './pipes/pointsPlaceMappingPipe/points-place-mapping.pipe';
import { SeasonFilterPipe } from './pipes/season-filter-pipe/season-filter.pipe';
import { TranslateFormulaPipe } from './pipes/translateFormulaPipe/translate-formula.pipe';
import { ValueWithUnitPipe } from './pipes/valueWithUnitPipe/value-with-unit.pipe';
import { BaseComponent } from "./components/baseComponent";
import { BaseDialogOpenerComponent } from "./components/baseDialogOpenerComponent";
import { PrintDownloadDialogComponent } from "./components/print-download-dialog/print-download-dialog.component";
import { LoaderInterceptor } from "./interceptor/loader.interceptor";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const providers = [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }, DecimalPipe];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [BaseComponent, BaseDialogOpenerComponent, TranslateModule, ValueWithUnitPipe, AgeGroupNamePipe, RankedPlaceComponent, DetectFocusDirective, SelectOnFocusDirective, TranslateFormulaPipe, PointsPlaceMappingPipe, LoaderComponent, NameFilterPipe, SeasonFilterPipe, AgegroupFilterPipe, YesNoDialogComponent, PrintDownloadDialogComponent],
  providers: providers,
  declarations: [BaseComponent, BaseDialogOpenerComponent, ValueWithUnitPipe, AgeGroupNamePipe, RankedPlaceComponent, DetectFocusDirective, SelectOnFocusDirective, TranslateFormulaPipe, PointsPlaceMappingPipe, LoaderComponent, NameFilterPipe, SeasonFilterPipe, AgegroupFilterPipe, YesNoDialogComponent, PrintDownloadDialogComponent],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: providers,
    };
  }
}
