import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AuthHttpService } from "../../shared/services/authService/auth.http.service";
import {
  AuthActionTypes,
  loginFailed,
  loginFinished,
  refreshTokenFailed,
  refreshTokenFinished,
  updateUserInformationFinished
} from "./auth.actions";
import { catchError, switchMap } from "rxjs/operators";
import { map, of } from "rxjs";
import { ITokenResponse } from "../../../interfaces/tokenResponse.interface";
import { IAclUser } from "../../../interfaces/userAcl.interface";
import { HttpErrorResponse } from "@angular/common/http";
import jwtDecode from "jwt-decode";
import { IAuthTokenPayload } from "../../../interfaces/authTokenPayload.interface";
import { TokenInterceptor } from "../../shared/interceptor/token.interceptor";

@Injectable()
export class AuthEffects {

  login$ = createEffect(() => {
    return this.actions$.pipe(ofType(AuthActionTypes.Login), switchMap(
      (action: { username: string, password: string }) => this.authService.login(action.username, action.password).pipe(
        map((token: ITokenResponse) => loginFinished({
          token: token
        })), catchError((error: HttpErrorResponse) => of(loginFailed({ error }))))));
  });

  refreshToken$ = createEffect(() => {
    return this.actions$.pipe(ofType(AuthActionTypes.RefreshToken), switchMap((action: { refreshToken: string }) => {
      if (this.isRefreshTokenValid(action.refreshToken)) {
        return this.authService.refreshToken(action.refreshToken).pipe(map((token: ITokenResponse) => {
          return refreshTokenFinished({ token: token });
        }), catchError((error: HttpErrorResponse) => {
          return of(refreshTokenFailed({ error }));
        }));
      } else {
        return of(refreshTokenFailed({ error: new Error("token invalid") }));
      }
    }));
  });

  updateUserInformation$ = createEffect(() => {
    return this.actions$.pipe(ofType(AuthActionTypes.UpdateUserInformation), switchMap(
      () => this.authService.me().pipe(map((aclUser: IAclUser) => updateUserInformationFinished({ user: aclUser })))));
  });

  constructor(private actions$: Actions, private authService: AuthHttpService) {
  }

  public isRefreshTokenValid(token: string): boolean {
    let refToken = jwtDecode<IAuthTokenPayload>(token);
    return refToken.exp * 1000 >= Date.now() - TokenInterceptor.TOKEN_VALID_TIME_RESERVE_MS;

  }
}
