import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, filter, Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { getTokenInformation } from "../../auth/auth-store/auth.selectors";
import { AuthState } from "../../auth/auth-store/auth.reducer";
import { ITokenResponse } from "../../../interfaces/tokenResponse.interface";
import { refreshToken } from "../../auth/auth-store/auth.actions";
import { switchMap, take } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public static readonly TOKEN_VALID_TIME_RESERVE_MS = 900;
  private readonly production: boolean = environment.production;
  private authStore: Store<AuthState> = inject(Store<AuthState>);

  private token: ITokenResponse;
  private refreshTokenInProgress: boolean = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    this.authStore.select(getTokenInformation).subscribe((token) => {
      if (token && this.token?.access_token != token.access_token) {
        this.token = token;
        this.refreshTokenSubject.next(token.access_token);
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get token url - no access token needed
    if (request.url.includes("/oauth/token")) {
      return next.handle(request);
    }

    // assets url - no access token needed
    if (request.url.includes("assets/")) {
      return next.handle(request);
    }

    // preflight requests - no access token needed
    if (request.method === "OPTIONS") {
      return next.handle(request);
    }

    // already authenticated - no interception necessary
    if (request.headers.has("authorization")) {
      return next.handle(request);
    }

    if (!this.production) console.log(`INTERCEPT: ${request.url} (${request.method})`);

    if (this.token) {
      this.refreshTokenInProgress = this.isRefreshTokenInProgress(this.token);

      if (this.refreshTokenInProgress) {
        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
        // – which means the new token is ready and we can retry the request again
        return this.refreshTokenSubject.pipe(filter(result => result !== null), take(1), switchMap(() => {
          return next.handle(this.addAuthenticationToken(request));
        }));
      } else {
        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
        this.refreshTokenSubject.next(null);
        return next.handle(this.addAuthenticationToken(request));
      }
    }

    // return original request since no token available
    return next.handle(request);
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    // Get access token from Local Storage
    const accessToken = this.token?.access_token;

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  isRefreshTokenInProgress(token: ITokenResponse) {
    if (this.isExpired(token)) {
      if (this.refreshTokenInProgress) return true;
      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);
      this.authStore.dispatch(refreshToken({ refreshToken: this.token.refresh_token }));
      return true;
    } else {
      return false;
    }
  }

  private isExpired(token: ITokenResponse): boolean {
    // multiply with 1000 to be on ms level and reduce by token refresh interval
    const expiresAt = token.expires_at * 1000 - TokenInterceptor.TOKEN_VALID_TIME_RESERVE_MS;
    const now = Date.now();
    return token && expiresAt < now;
  }
}
