import { Component, HostBinding, inject, Input } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigService } from "../../config/config.service";
import { LayoutService } from "../../services/layout.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "vex-toolbar", templateUrl: "./toolbar.component.html", styleUrls: ["./toolbar.component.scss"]
})
export class ToolbarComponent {
  @Input() mobileQuery: boolean;
  @Input() @HostBinding("class.shadow-b") hasShadow: boolean;
  private layoutService: LayoutService = inject(LayoutService);
  private configService: ConfigService = inject(ConfigService);
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === "vertical"));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === "below-toolbar"));
  private translateService: TranslateService = inject(TranslateService);

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  getLanguageIcon(): string {
    return this.translateService.currentLang;
  }

  selectLang(lang: string): void {
    this.translateService.use(lang);
  }
}
