import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { BaseLayoutComponent } from './base-layout/base-layout.component';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/auth-login/auth-login.module').then((m) => m.AuthLoginModule),
  },
  {
    path: 'verify',
    loadChildren: () => import('./auth/auth-verify/auth-verify.module').then((m) => m.AuthVerifyModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./auth/auth-register/auth-register.module').then((m) => m.AuthRegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/auth-forgot-password/auth-forgot-password.module').then((m) => m.AuthForgotPasswordModule),
  },
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'championstour',
        loadChildren: () => import('./championstour/championstour.module').then((m) => m.ChampionstourModule),
      },
      {
        path: 'european-championship',
        loadChildren: () => import('./european-championship/european-championship.module').then((m) => m.EuropeanChampionshipModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    useHash: true
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
