import { Action, createAction, props } from "@ngrx/store";
import { ITokenResponse } from "../../../interfaces/tokenResponse.interface";
import { IAclUser } from "../../../interfaces/userAcl.interface";
import { HttpErrorResponse } from "@angular/common/http";

export enum AuthActionTypes {
  Login = "[Auth] login",
  LoginFinished = "[Auth] login finished",
  LoginFailed = "[Auth] login failed",

  Logout = "[Auth] logout",
  LogoutFinished = "[Auth] logout finished",

  RefreshToken = "[Auth] refresh current token",
  RefreshTokenFinished = "[Auth] refresh of current token finished",
  RefreshTokenFailed = "[Auth] refresh of current token failed",

  UpdateUserInformation = "[Auth] update user information",
  UpdateUserInformationFinished = "[Auth] update user information finished"
}

export const AUTH_STORAGE = '@ngrx/store/storage';

export class AuthStorageAction implements Action {
  readonly type = AUTH_STORAGE;
  constructor(readonly payload: string) {}
}

export const login = createAction(AuthActionTypes.Login, props<{ username: string, password: string }>());

export const loginFinished = createAction(AuthActionTypes.LoginFinished, props<{ token: ITokenResponse }>());

export const loginFailed = createAction(AuthActionTypes.LoginFailed, props<{ error: HttpErrorResponse }>());

export const logout = createAction(AuthActionTypes.Logout);

export const logoutFinished = createAction(AuthActionTypes.LogoutFinished);

export const refreshToken = createAction(AuthActionTypes.RefreshToken, props<{ refreshToken: string }>());

export const refreshTokenFinished = createAction(AuthActionTypes.RefreshTokenFinished,
  props<{ token: ITokenResponse }>());

export const refreshTokenFailed = createAction(AuthActionTypes.RefreshTokenFailed,
  props<{ error: HttpErrorResponse | Error }>());

export const updateUserInformation = createAction(AuthActionTypes.UpdateUserInformation);

export const updateUserInformationFinished = createAction(AuthActionTypes.UpdateUserInformationFinished,
  props<{ user: IAclUser }>());
