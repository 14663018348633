import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { finalize, Observable } from "rxjs";
import { LoaderService } from "../services/loader/loader.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private loader: LoaderService = inject(LoaderService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loader.showLoader();
    return next.handle(request).pipe(finalize(() => this.loader.hideLoader()));
  }
}
