import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuthGrantTypes } from 'src/enumerations/grantTypes.enum';
import { environment } from 'src/environments/environment';
import { IUserRegistration } from 'src/interfaces/registration.interface';
import { ITokenResponse } from 'src/interfaces/tokenResponse.interface';
import { IUser } from 'src/interfaces/user.interface';
import { IAclUser } from 'src/interfaces/userAcl.interface';
import { IUserVerification } from 'src/interfaces/verification.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  private http: HttpClient = inject(HttpClient);

  public login(username: string, password: string): Observable<ITokenResponse> {
    let body = new URLSearchParams();
    body.set('grant_type', OAuthGrantTypes.PASSWORD);
    body.set('client_id', environment.clientId);
    body.set('client_secret', environment.clientSecret);
    body.set('username', username);
    body.set('password', password);

    let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let options = { headers: headers };

    return this.http.post<ITokenResponse>(environment.backendApiUrl + '/oauth/token', body, options);
  }

  public refreshToken(refreshToken: string): Observable<ITokenResponse> {
    let body = new URLSearchParams();
    body.set('grant_type', OAuthGrantTypes.REFRESH_TOKEN);
    body.set('client_id', environment.clientId);
    body.set('client_secret', environment.clientSecret);
    body.set('refresh_token', refreshToken);

    let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let options = { headers: headers };

    return this.http.post<ITokenResponse>(environment.backendApiUrl + '/oauth/token', body, options);
  }

  public register(username: string, name: string, lastname: string, email: string): Observable<IUser> {
    let reg: IUserRegistration = {
      email: email,
      lastname: lastname,
      name: name,
      username: username,
    };
    let body = JSON.stringify(reg);

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.put<IUser>(environment.backendApiUrl + '/user/register', body, options);
  }

  public verify(verificationCode: string, password: string): Observable<IUser> {
    let reqBody: IUserVerification = {
      verificationCode: verificationCode,
      password: password,
    };
    let body = JSON.stringify(reqBody);

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.http.patch<IUser>(environment.backendApiUrl + '/user/verify', body, options);
  }

  public me(): Observable<IAclUser> {
    return this.http.get<IAclUser>(environment.backendApiUrl + '/user/me', {});
  }
}
