import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { PopoverService } from "../../../components/popover/popover.service";
import { ToolbarUserDropdownComponent } from "./toolbar-user-dropdown/toolbar-user-dropdown.component";
import { select, Store } from "@ngrx/store";
import { getNameOfUser, isLoggedIn } from "../../../../app/auth/auth-store/auth.selectors";
import { Observable } from "rxjs";
import { AuthState } from "../../../../app/auth/auth-store/auth.reducer";

@Component({
  selector: "vex-toolbar-user",
  templateUrl: "./toolbar-user.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {
  dropdownOpen: boolean;
  isLoggedIn$: Observable<boolean>;
  nameOfUser$: Observable<string>;
  private popover: PopoverService = inject(PopoverService);
  private cd: ChangeDetectorRef = inject(ChangeDetectorRef);
  private authStore: Store<AuthState> = inject(Store<AuthState>);

  ngOnInit() {
    this.isLoggedIn$ = this.authStore.pipe(select(isLoggedIn));
    this.nameOfUser$ = this.authStore.pipe(select(getNameOfUser));
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent, origin: originRef, offsetY: 12, position: [{
        originX: "center", originY: "top", overlayX: "center", overlayY: "bottom"
      }, {
        originX: "end", originY: "bottom", overlayX: "end", overlayY: "top"
      }]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }
}
